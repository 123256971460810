import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { appURLs, hardCodeURLs } from "@/app/constants.mjs";
import {
  GlobeSection,
  JoinSection,
  MainSections,
  MissionSection,
  PageContainer,
  PillarsSection,
} from "@/marketing/AboutUs.style.jsx";
import MarketingFooter from "@/marketing/MarketingFooter.jsx";
import MarketingHeader from "@/marketing/MarketingHeader.jsx";
import GlobeRenderer from "@/shared/GlobeRenderer.jsx";

const ABOUTUS_HEADER_COVER = `${appURLs.CDN}/blitz/marketpages/about-us/header-cover-1a729.webp`;

const PILLARS: Array<{
  title: Translation;
  desc: Translation;
}> = [
  {
    title: ["home:aboutUs.trustworthiness.title", "Trustworthiness"],
    desc: [
      "home:aboutUs.trustworthiness.desc",
      "Gamers trust Blitz to deliver the most up-to-date builds, guides, tips, and insights. Like a good coach, Blitz gives you the fundamentals to improve then gets out of your way and lets you play to your strengths.",
    ],
  },
  {
    title: ["home:aboutUs.inclusivity.title", "Inclusivity"],
    desc: [
      "home:aboutUs.inclusivity.desc",
      "Blitz helps gamers of any skill level improve their play. Whether you're new to competitive gaming or a seasoned veteran, anyone can enjoy how Blitz automates and simplifies the learning process.",
    ],
  },
  {
    title: ["home:aboutUs.energy.title", "Energy"],
    desc: [
      "home:aboutUs.energy.desc",
      "Gamers use Blitz because they know it works fast, delivering real-time insights and enhancements. We're constantly adapting and staying ahead of the meta without losing steam.",
    ],
  },
  {
    title: ["home:aboutUs.confidence.title", "Confidence"],
    desc: [
      "home:aboutus.confidence.desc",
      "With a sleek and striking design, Blitz projects gaming expertise. Backed by esports pros and top-notch data nerds, we know what makes gaming easier and how to help players get better.",
    ],
  },
  {
    title: ["home:aboutUs.empowerment.title", "Empowerment"],
    desc: [
      "home:aboutUs.empowerment.desc",
      "Blitz is like a good mentor, not a commander. Downloading Blitz gives players the direction and motivation to enter the competitive gaming scene and rank up with less stress and confusion.",
    ],
  },
];

export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <MarketingHeader />

      <MissionSection $bgImage={ABOUTUS_HEADER_COVER}>
        <div>
          <h1>{t("home:aboutUs.ourMission.title", "Our Mission")}</h1>
          <p>
            <Trans i18nKey="home:aboutUs.ourMission.desc">
              Become <b>every gamer&apos;s personal gaming coach</b>, helping
              them improve with unique <b>performance insights</b> and{" "}
              <b>learning tools.</b>
            </Trans>
          </p>
        </div>
      </MissionSection>

      <MainSections>
        <PillarsSection>
          <h2 className="type-h3">
            {t(
              "home:aboutUs.ourBrand",
              "Our brand pillars remind us of who we are, and what we want to become.",
            )}
          </h2>

          <ul>
            {PILLARS.map((dataItem, i) => (
              <li key={i}>
                <h3 className="type-h6">{t(...dataItem.title)}</h3>
                <p>{t(...dataItem.desc)}</p>
              </li>
            ))}
          </ul>
        </PillarsSection>

        <GlobeSection>
          <div className="text-block">
            <h2>{t("home:aboutUs.ourTeam.title", "Our team")}</h2>
            <p>
              {t(
                "home:aboutUs.ourTeam.desc1",
                "Headquartered in LA and supported by a global remote workforce, we always seek big opportunities and live by our motto of improving one game at a time.",
              )}
            </p>
            <p>
              {t(
                "home:aboutUs.ourTeam.desc2",
                "We're zoomers, boomers, millennials, esports fanatics, and casual gamers united by our love of dreaming big and making an impact.",
              )}
            </p>
          </div>

          <div className="globe">
            <GlobeRenderer />
          </div>
        </GlobeSection>
      </MainSections>

      <JoinSection>
        <div>
          <h3>{t("home:aboutUs.joinTheSquad.title", "Join the squad")}</h3>
          <p>
            {t(
              "home:aboutUs.joinTheSquad.desc",
              "We're constantly inspired by the future of gaming and eager to be a part of it. Join us if you want to make great things, learn new skills, and rank up IRL.",
            )}
          </p>
          <a href="/careers">
            {t("home:aboutUs.viewAllCareers", "View All Careers")}
          </a>
        </div>
      </JoinSection>
      <MarketingFooter expanded />
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["home:meta.aboutUs.title", "Blitz.gg - About Us"],
    description: [
      "home:meta.aboutUs.description",
      "Founded by pro gamers, Blitz is the ultimate gaming companion app that helps you improve your play.",
    ],

    schema: {
      "@type": "Organization",
      name: "Blitz",
      url: appURLs.BLITZ,
      logo: `${appURLs.CDN}/blitz/ui/img/logos/logo-094d65.webp`,
      sameAs: [
        hardCodeURLs.BLITZ_FACEBOOK,
        hardCodeURLs.BLITZ_TWITTER,
        hardCodeURLs.BLITZ_INSTAGRAM,
        hardCodeURLs.BLITZ_DISCORD,
        hardCodeURLs.BLITZ_TIKTOK,
        hardCodeURLs.BLITZ_NAVER,
        hardCodeURLs.BLITZ_MEDIUM,
      ],
    },
  };
}
